// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true, // works for actions, mutations and getters... because for state its namespaced by default
  state: {
    exploreView: {
      layout_tabs: {
        _a: ['GraphLibraryWidget', 'BrainLibraryWidget', 'GraphControlsWidget'],
        _b: ['GoalsWidget', 'GraphExploreWidget', 'DendroGraphWidget', 'BrainLibraryWidget', 'ThoughtMapWidget', 'NotesLibraryWidget', 'VideosWidget', 'DrawWidget', 'LinkedThoughtsWidget'],
        _c: ['NotesLibraryWidget', 'GraphLibraryWidget'],
        _d: ['QuestCheckpointWidget', 'GraphIndexWidget'],
        _e: ['DetailWidget', 'EddieWidget'],
        _f: ['WikiDataCardWidget', 'SmartGraphWidget', 'DetailWidget'],
        _g: [],
        _h: [],
        _i: [],
        _j: [],
        _k: [],
        _l: [],
        _m: [],
        _n: [],
      },
      layout_tab_selection: {
        _a: 0,
        _b: 1,
        _c: 1,
        _d: 0,
        _e: 0,
        _f: 1,
        _g: 0,
        _h: 0,
        _i: 0,
        _j: 0,
        _k: 0,
        _l: 0,
        _m: 0,
        _n: 0,
      },
      activeLayoutKey: '_b',
    },
    gameView: {
      layout_tabs: {
        _a: ['DetailWidget'],
        _b: ['GraphExploreWidget', 'DendroGraphWidget'],
      },
      layout_tab_selection: {
        _a: 0,
        _b: 0,
      },
      activeLayoutKey: '_b',
    },
    bootcampView: {
      layout_tabs: {
        _a: ['GraphLibraryWidget', 'GraphControlsWidget'],
        _b: ['GraphExploreWidget', 'NotesLibraryWidget'],
        _c: [],
        _d: [],
        _e: [],
        _f: ['DetailWidget', 'EditorWidget', 'VideosWidget'],
        _g: [],
        _h: [],
        _i: [],
        _j: [],
        _k: [],
        _l: [],
        _m: [],
        _n: [],
      },
      layout_tab_selection: {
        _a: 0,
        _b: 0,
        _c: 0,
        _d: 0,
        _e: 0,
        _f: 0,
        _g: 0,
        _h: 0,
        _i: 0,
        _j: 0,
        _k: 0,
        _l: 0,
        _m: 0,
        _n: 0,
      },
      activeLayoutKey: '_b',
    },
    SignalSchoolView: {
      layout_tabs: {
        _a: ['GraphLibraryWidget'],
        _b: ['DendroGraphWidget'],
        _c: [],
        _d: [],
        _e: [],
        _f: ['DetailWidget'],
        _g: [],
        _h: [],
        _i: [],
        _j: [],
        _k: [],
        _l: [],
        _m: [],
        _n: [],
      },
      layout_tab_selection: {
        _a: 0,
        _b: 0,
        _c: 0,
        _d: 0,
        _e: 0,
        _f: 0,
        _g: 0,
        _h: 0,
        _i: 0,
        _j: 0,
        _k: 0,
        _l: 0,
        _m: 0,
        _n: 0,
      },
      activeLayoutKey: '_b',
    },
    authorView: {
      layout_tabs: {
        _a: ['NotesLibraryWidget', 'Twitterfeed'],
        _b: ['EddieWidget', 'GraphIndexWidget'],
        _c: ['DetailWidget'],
        _d: ['SnippetWidget'],
        _e: ['WikiDataCardWidget'],
        _f: ['ThreeDModelWidget'],
      },
      layout_tab_selection: {
        _a: 0,
        _b: 1,
        _c: 0,
        _d: 0,
        _e: 0,
        _f: 0,
      },
      activeLayoutKey: '_b',
    },
    simplifiedView: {
      layout_tabs: {
        _a: ['GlossaryWidget'],
        _b: ['DetailWidget'],
        _c: ['GraphControlsWidget'],
        _d: ['VideosWidget', 'WikiDataCardWidget'],
        _e: [],
        _f: [],
      },
      layout_tab_selection: {
        _a: 0,
        _b: 0,
        _c: 0,
        _d: 1,
        _e: 0,
        _f: 0,
      },
      activeLayoutKey: '_b',
    },
    mobileView: {
      layout_tabs: {
        _a: ['SearchWidget', 'DetailWidget', 'VideosWidget', 'EddieWidget', 'GraphExploreWidget'],
        _b: ['DetailWidget'],
        _c: ['GraphControlsWidget'],
        _d: ['VideosWidget', 'WikiDataCard'],
        _e: [],
        _f: [],
      },
      layout_tab_selection: {
        _a: 0,
        _b: 0,
        _c: 0,
        _d: 0,
        _e: 0,
        _f: 0,
      },
      activeLayoutKey: '_a',
    },
    readingView: {
      layout_tabs: {
        _a: ['GlossaryWidget'],
        _b: ['DetailWidget'],
        _c: ['GraphControlsWidget'],
        _d: ['VideosWidget', 'WikiDataCard'],
        _e: [],
        _f: [],
      },
      layout_tab_selection: {
        _a: 0,
        _b: 0,
        _c: 0,
        _d: 0,
        _e: 0,
        _f: 0,
      },
      activeLayoutKey: '_b',
    },
    edit: false,
    resize: false,
    view: 'exploreView',
  },
  actions,
  mutations,
};
