<template>
  <div
    class="d-inline-flex sidebar-dark flex-column flex-shrink-0 text-white sidebar-fn sidebar-width"
    :class="{
      'sidebar-open': sidebarToggleStatus,
      'sidebar-close': !sidebarToggleStatus,
    }"
  >
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item" v-for="route in routes" :key="route.id">
        <router-link
          :to="route.uri"
          class="d-flex nav-item__link"
          aria-current="page"
          activeClass="nav-item__link--active"
          exact
        >
          <i :class="`nav-item__icon bx bx-${route.icon.name}`"></i>
          {{ $t(route.name) }}
        </router-link>
      </li>
    </ul>

    <hr />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/common/components/Footer.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Sidebar',
  components: {
    Footer,
  },

  data() {
    return {
      routes: [
        {
          id: 1, name: 'comp_sidebar--r1', icon: { name: 'home-alt' }, uri: '/',
        },
        {
          id: 2, name: 'comp_sidebar--r2', icon: { name: 'stats' }, uri: '/dashboard',
        },
        {
          id: 6, name: 'comp_sidebar--r6', icon: { name: 'slider-alt' }, uri: '/Settings',
        },
        {
          id: 8, name: 'comp_sidebar--r11', icon: { name: 'accessibility' }, uri: '/accessibility',
        },
      ],
      points: null, // Store points here
    };
  },

  created() {
    this.fetchPoints();
  },

  methods: {
    ...mapActions({
      logoutAction: 'auth/logout',
      pointsAction: 'points/setPoints',
    }),

    async fetchPoints() {
      try {
        const data = await this.pointsAction(this.username);
        this.points = data.data.points; // Update data property
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    },

    logout() {
      this.logoutAction().then(() => {
        this.$router.push({ name: 'Auth', params: { action: 'Login' } });
      });
    },
  },

  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),

    sidebarToggleStatus() {
      return this.$store.state.uiStates.sidebarToggleStatus;
    },
    username() {
      return this.user?.username || '';
    },
    email() {
      return this.user.email;
    },
    roles() {
      return this.user.roles.join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";

.sidebar-dark {
  background-color: rgba($edvantaBlue, .05);
  opacity: 1;
  width: 15%;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 80%;
  }
}

.username-container {
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.sidebar-fn {
  z-index: 4;
  position: fixed;
  top: 0;
  height: 100vh;
  padding-top: 10vh;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding-top: 5vh;
  }

  @media (max-width: 480px) {
    padding-top: 2vh;
  }
}

.sidebar-open {
  transform: translate(0%);
}

.sidebar-close {
  transform: translate(-100%);
}

.nav-item__icon {
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem; /* Smaller icons for mobile */
  }
}

.nav-item {
  margin-bottom: .5rem;

  &__link {
    color: $edvantaBlue;
    text-decoration: none;
    padding: .5rem;
    border-radius: $kRadius;

    &:hover {
      background-color: rgba($edvantaBlue, .06);
    }

    &--active {
      background-color: rgba($edvantaBlue, .06);
    }
  }

  &__icon {
    fill: currentColor;
    color: currentColor;
    font-size: 1.5rem;
    margin-right: .5rem;
  }
}

.user-dropdown-menu__user-info {
  padding: 10px;
  background-color: #ffffff;
  color: #000000;
  text-align: center;

  @media (max-width: 768px) {
    text-align: left;
  }
}

.username {
  display: block;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 3px;
  margin-left: 3rem;
  color: #000000;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.email, .points {
  display: block;
  font-size: 10px;
  color: #000000;
}

.points {
  margin-top: 5px;
  font-weight: bold;
}

.custom-power-icon {
  color: black;
  font-size: 2rem;
}

.logout-icon-container {
  display: flex;
  justify-content: right;
  margin-top: 5rem;
}

.custom-power-button {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.custom-power-button:focus {
  outline: none;
  border: none;
}

.custom-power-button:hover {
  border: none;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
               0 0 13px rgba(0, 0, 139, 0.5),
               0 0 18px rgba(0, 0, 139, 0.5),
               0 0 23px rgba(0, 0, 139, 0.5),
               0 0 28px rgba(0, 0, 139, 0.5),
               0 0 33px rgba(0, 0, 139, 0.5);
}

.custom-power-icon {
   font-size: 2rem;
  color: black;
}

.custom-power-text {
  font-size: 0.9rem;
  color: black;
  margin: 0;
}
</style>
